<template>
  <div>
    <v-list v-if="hasTherapiesToApprove" class="mb-3">
      <v-list-item
        v-for="(appointment, index) in appointments"
        :key="appointment.appointmentId"
        class="border-bottom-grey"
      >
        <v-list-item-content>
          <v-list-item-title>
            Paciente: {{ appointment.patientName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Dia: {{ appointment.startAt | formatDate }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Horário: {{ appointment.startAt | formatTime }}h às
            {{ appointment.endAt | formatTime }}h
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex">
            <v-btn
              class="mr-2"
              icon
              large
              @click="openDetails(appointment.appointmentId)"
            >
              <tooltip-icon
                color="blue"
                text="Ver informações"
                icon="open_in_new"
                large
              />
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              large
              @click="approve(appointment.appointmentId, index)"
            >
              <tooltip-icon
                color="green"
                text="Confirmar"
                icon="check_circle_outline"
                large
              />
            </v-btn>
            <v-btn
              icon
              large
              @click="openCancellationDialog(appointment.appointmentId, index)"
            >
              <tooltip-icon
                color="red"
                text="Cancelar"
                icon="remove_circle_outline"
                large
              />
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <template v-else-if="notSupportedPlanOfLoggedUser">
      <empty-list
        text="Você não possui plano ativo ou seu plano atual não suporta agendamentos"
        icon="warning"
        icon-color="warning"
      />
    </template>
    <template v-else>
      <empty-list text="Nenhum agendamento pendente de aprovação" />
    </template>
    <cancellation-dialog ref="cancellationDialog" @submit="cancelled" />
    <detail-dialog ref="detailDialog" />
  </div>
</template>

<script>
import {
  approveAppointment,
  cancelAppointment,
  getAppointmentsToApprove,
} from "@/services/external/appointments-service";

export default {
  name: "ToApprove",
  components: {
    CancellationDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/CancellationDialog"
      ),
    DetailDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/DetailDialog"
      ),
  },
  data: () => ({
    appointments: [],
    cancellationIndex: -1,
    notSupportedPlanOfLoggedUser: false,
  }),
  computed: {
    hasTherapiesToApprove() {
      return this.appointments && this.appointments.length > 0;
    },
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
      try {
        const { data } = await getAppointmentsToApprove();
        console.log(data);
        this.appointments = data;
      } catch (error) {
        if (error?.response?.status === 403) {
          this.notSupportedPlanOfLoggedUser = true;
        } else {
          this.$errorHandler(e);
        }
      }
    },
    async approve(appointmentId, index) {
      try {
        this.$store.dispatch("loading/openDialog");
        await approveAppointment(appointmentId);
        this.appointments.splice(index, 1);
        this.$toasted.global.success({
          message: "Agendamento confirmado. O paciente foi notificado.",
        });
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    openCancellationDialog(appointmentId, index) {
      this.cancellationIndex = index;
      this.$refs.cancellationDialog.open({
        appointmentId,
        source: "therapist",
      });
    },
    cancelled() {
      this.appointments.splice(this.cancellationIndex, 1);
    },
    openDetails(id) {
      this.$refs.detailDialog.open(id);
    },
  },
};
</script>

<style scoped></style>
